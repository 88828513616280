const { today, nextWeek } = require('./dates')

module.exports = {
    id: 'de',
    label: 'Deutsch',
    filename: 'Bestellformular.pdf',
    subject: (failure, user) => `Beendigung des Auftrags ${failure.number ? `Nr. ${failure.number}` : ''}`,
    html: (failure, user) => `<p>Sehr geehrter Kunde, am ${today('de')} haben wir Ihre Reparaturanforderung Nr. ${failure.number} ${failure.order_number ? ` auf Grundlage Ihrer Bestellung mit der Nr. ${failure.order_number}` : ''} beendet</p><br/>
    <p>Eine Zusammenfassung Ihrer Bestellung finden Sie in dem Anhang dieser Nachricht.</p><br/>
    <p>Das voraussichtliche Datum der Auslieferung ist ${today('de')}</p><br/>
    ${failure.number ? `<p>Bei Fragen verwenden Sie bitte immer unsere Identifikationsnummer ${failure.number} und ` : ''} kontaktieren Sie uns unter E-Mail servis@electric-components.cz ${user.email ? `oder ${user.email}</p><br/>` : ''}

<p>Wir freuen uns auf eine weitere Zusammenarbeit und verbleiben mit freundlichen Grüßen.</p><br/>

<p>${user.name ? `${user.name}` : ''}<br/>
Electric Components a.s.<br/>
<img src="https://firebasestorage.googleapis.com/v0/b/rams-e6d4f.appspot.com/o/logoLarge.png?alt=media&token=1591741a-7f97-48fe-a0d2-aefd57a11f46"/><p/>`
}
