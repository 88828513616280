<template>
  <a-field
    :field="field"
    :label="label"
    :type="value.length ? '' : 'is-warning'"
  >
    <b-taginput
      :data="remainingOptions"
      :value="value"
      open-on-focus
      allow-new
      :autocomplete="false"
      @typing="onTyping"
      @input="update"
    >
      <!-- <template slot-scope="props">
          {{ props.option }}
       </template> -->
    </b-taginput>
  </a-field>
</template>

<script>

import _ from 'lodash'

import Mixin from './Form/Mixin'
import AField from './Form/AField'

export default {
  components: {
    AField
  },
  props: {
    value: {
      type: Array,
      requried: true
    },
    options: {
      type: Array,
      requried: true
    },
    label: {
      type: String,
      requried: false
    }
  },
  computed: {
    availableOptions () {
      let self = this

      return self.typing ? _.filter(self.remainingOptions, option => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(self.typing.toLowerCase()) >= 0
      }) : self.remainingOptions
    },
    remainingOptions () {
      return _.filter(this.options, item => !_.includes(this.value, item))
    }
  },
  methods: {
    onTyping (input) {
      this.typing = input
    },
    update(value) {
      this.$emit('input', value)
    }
  }
}
</script>
