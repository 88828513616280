<template>
  <a-card
    :editable="false"
    :header="$t('failures.mail.receipt.header')"
  >
    <b-select
      :value="locale"
      :label="$t('common.select_language')"
      @input="changeLocale"
    >
      <option
        v-for="option in locales"
        :key="option.id"
        :value="option"
      >
        {{ option.label }}
      </option>
    </b-select>

    <b-select
      v-model="currency"
      :label="$t('common.currency')"
    >
      <option
        v-for="option in currencies"
        :key="option.id"
        :value="option"
      >
        {{ option.label }}
      </option>
    </b-select>

    <mailbox-taginput
      v-model="mail.to"
      :label="$t('failures.mail.receipt.recipients')"
      :options="contacts"
    />

    <b-input
      v-model="mail.message.subject"
    />

    <ckeditor
      v-model="mail.message.html"
      :editor="editor"
      :config="editorConfig"
    />
    <br>

    <b-table
      v-if="parts"
      :data="parts"
    >
      <b-table-column
        v-if="options.is_final"
        v-slot="props"
        field="checked"
        :label="$t('failures.mail.receipt.include_in_this_overview')"
      >
        <b-switch v-model="props.row.checked" />
        <span
          v-if="props.row.sent"
          class="tag is-primary is-light is-rounded is-small"
        >
          {{ moment(props.row.sent.toDate()).format('D.M.YY') }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="part.name"
        :label="$t('parts.ref.name')"
      >
        {{ props.row.part ? props.row.part.name : '' }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="number"
        :label="$t('parts.ref.number')"
      >
        {{ props.row.number }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="name"
        :label="`${$t('common.description')} ${options.is_final ? $t('failures.mail.receipt.not_visible_in_PDF') : ''}`"
      >
        <b-input
          v-model="props.row.name"
          :disabled="options.is_final"
          type="textarea"
        />
      </b-table-column>

      <b-table-column
        v-if="options.is_final"
        v-slot="props"
        field="resolution"
        :label="$t('failures.props.resolution')"
      >
        <b-input
          v-model="props.row.resolution"
          type="textarea"
        />
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="price"
        :label="options.is_final ? $t('failures.mail.receipt.final_price') : $t('failures.mail.receipt.estimated_price')"
      >
        <b-input
          v-model="props.row.price"
          type="number"
          step="1"
          min="0"
        />
      </b-table-column>
    </b-table>

    <b-message
      v-if="sendingState"
      :title="deliveryMessage"
      aria-close-label="Close message"
      :type="messageType"
    >
      <b-button
        v-if="deliveryState"
        @click="deliveryButtonAction"
      >
        {{ deliveryState === 'ERROR' ? $t('failures.mail.receipt.send_again') : $t('common.close') }}
      </b-button>
    </b-message>

    <div
      v-else
      class="buttons is-pulled-right"
    >
      <b-button
        icon-left="attachment"
        @click="createAttachmentAndClose"
      >
        {{ $t('failures.mail.receipt.create_attachment') }}
      </b-button>
      <b-button
        icon-left="send"
        type="is-success"
        :disabled="!mail.to.length"
        :title="$t('failures.mail.receipt.fill_in_recepients')"
        @click="sendMail"
      >
        {{ $t('failures.mails.send_button') }}
      </b-button>
    </div>


    <div class="is-clearfix" />
  </a-card>
</template>

<script>
import firebase from 'firebase'
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import MailboxTaginput from '@/components/MailboxTaginput'

const currencies = [
  { id: 'CZK', label: 'CZK' },
  { id: 'EUR', label: 'EUR' },
  { id: 'CHF', label: 'CHF' }
]


export default {
  components: {
    MailboxTaginput
  },
  props: {
    options: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      // locales,
      editor: ClassicEditor,
      editorConfig: {},
      sentMail: null,
      currency: null,
      currencies,
      locale: null,
      status: false,
      settings: {},
      mail: {
        to: [],
        message: {
          attachments: [],
          subject: null,
          html: null
        }
      },
      attachment: {
        path: null,
        contentType: 'application/pdf'
      },
      template: 'OrderForm.vue'
    }
  },
  computed: {
    ...mapGetters({
      allParts: 'failureParts/currentFailureRootItems',
      allNodes: 'failureNodes/currentFailureRootItems'
    }),
    filename() {
      return `failures/${this.doc.id}/${this.attachment.filename}`
    },
    mailType() {
      return this.options.is_final ? 'overview' : 'receipt'
    },
    locales() {
      const type = this.options.is_final ? 'Overview' : 'Receipt'
      let locales = []
      _.forEach(['cs', 'en', 'de'], locale => {
        const required = require(`./${type}/${locale}`)
        locales.push(required)
      })
      return locales
    },
    rootParts() {
      return _.map(_.filter(this.allParts, item => item.parent === null), item => {
        item.collection = 'failureParts'
        return item
      })
    },
    rootNodes() {
      return _.map(_.filter(this.allNodes, item => item.parent === null), item => {
        item.collection = 'failureNodes'
        return item
      })
    },
    contacts() {
      return _.map(_.values(this.doc.contacts), 'email')
    },
    deliveryState() {
      if(this.sentMail && this.sentMail.delivery && this.sentMail.delivery.state) {
        return this.sentMail.delivery.state
      }
      return null
    },
    sendingState() {
      switch(this.deliveryState) {
      case 'SUCCESS':
        return 'sending_email_success'
      case 'ERROR':
        return 'sending_email_error'
      default:
        return this.status
      }
    },
    messageType() {
      switch(this.sendingState) {
      case('sending_email_error'):
      case('creating_attachment_error'):
        return 'is-danger'
      case('sending_email_success'):
      case('creating_attachment_success'):
        return 'is-success'
      default:
        return 'is-default'
      }
    },
    deliveryMessage() {
      const options = [
        { value: 'sending_email_error', label: this.$t('failures.mail.receipt.sending_state.sending_email_error')},
        { value: 'creating_attachment_error', label: this.$t('failures.mail.receipt.sending_state.creating_attachment_error')},
        { value: 'sending_email_success', label: this.$t('failures.mail.receipt.sending_state.sending_email_success')},
        { value: 'creating_attachment_success', label: this.$t('failures.mail.receipt.sending_state.creating_attachment_success')}
      ]

      const found = _.find(options, { value: this.sendingState })
      return found ? found.label : this.$t(`failures.mail.receipt.sending_state.${this.sendingState}`)
    },
    statusBarText() {
      switch(this.deliveryState) {
      case 'ERROR':
        return this.$t('failures.mail.receipt.send_again')
        break
      case 'SUCCESS':
        return this.$t('common.close')
        break
      default:
        return null
      }
    }
  },
  watch: {
    deliveryState(value) {
      if(value === 'SUCCESS') {
        this.$store.dispatch(`currentDoc/set`, {
          field: 'notified',
          value: this.mailType
        })
        if(this.options.is_final) {
          _.forEach(this.parts.filter(item => item.checked), item =>
            this.$store.dispatch(`${item.collection}/set`, { id: item.id, sent: new Date() })
          )
        }
      }
    }
  },
  async created() {
    const locale = _.find(this.locales, { id: this.user.locale })
    this.changeLocale(locale)
    const currency = _.find(this.currencies, { id: 'EUR' })
    console.log( { currency })
    this.currency = currency
    this.parts = _.map(_.concat(this.rootNodes, this.rootParts), item => {
      if(_.includes(['repaired', 'completed'], item.state) && !item.sent) {
        item.checked = true
      }
      item.price = 0
      return item
    })
  },
  methods: {
    moment,
    async filePath() {
      const storageRef = await firebase.storage().ref()
      const pathRef = await storageRef.child(this.filename)
      return await pathRef.getDownloadURL()
    },
    async sendMail () {
      await this.createAttachment()
      this.status = 'sending_email'
      let mail = _.cloneDeep(this.mail)
      let path = await this.filePath()
      mail.message.attachments[0] = {
        path,
        filename: this.attachment.filename,
        contentType: this.attachment.contentType
      }
      mail.failure = {
        id: this.doc.id,
        customer: {
          id: this.doc.customer.id
        }
      }
      mail.type = this.mailType
      const mailId = await this.$store.dispatch('mails/insert', mail)
      this.sentMail = this.$store.getters["mails/data"][mailId]
    },
    async createAttachment() {
      this.status = 'creating_attachment'

      const response = await fetch(`https://us-central1-rams-e6d4f.cloudfunctions.net/requests-createPdf`, {
        method: 'POST',
        body: JSON.stringify({
          filename: this.filename,
          data: {
            nodes: [],
            parts: _.map(
              this.options.is_final ? this.parts.filter(item => item.checked) : this.parts, item => {
                item.price = _.floor(item.price)
                return item
              }),
            failure: this.doc,
            settings: {
              currency: this.currency.id,
              is_final: this.options.is_final
            }
          },
          template: this.template,
          locale: this.locale.id,
          fullWidth: true
        })
      })
      console.log({ response })
      this.status = 'creating_attachment_success'
    },
    async createAttachmentAndClose() {
      await this.createAttachment()
      this.$emit('close', { reloadFiles: true })
    },
    changeLocale(locale) {
      this.mail.message.subject = locale.subject(this.doc, this.user)
      this.mail.message.html = locale.html(this.doc, this.user)
      this.attachment.filename = locale.filename
      this.locale = locale
    },
    deliveryButtonAction() {
      if(this.deliveryStatus === 'ERROR') {
        this.sendMail()
      } else {
        this.$emit('close', { reloadFiles: true })
      }
    }
  }
}
</script>
