const moment = require('moment')

module.exports = {
    id: 'en',
    label: 'English',
    filename: 'Recieved material for repair.pdf',
    subject: (failure, user) => `Recieved material for repair ${failure.number ? `No. ${failure.number}` : ''}`,
    html: (failure, user) => `<p>Dear customer,</p>
${failure.progress.reported.at ? `<p>on ${moment(failure.progress.reported.at).locale('en').format('LL')}` : ''} we recieved the material for repair according to the attached file.</p>
${failure.order_number ? `<p>Your order: ${failure.order_number}</p>` : ''}
${failure.progress.estimated.at ? `<p>Please note that the estimated completion date of repair is ${moment(failure.progress.estimated.at).locale('en').format('LL')}</p>` : ''}
${failure.number ? `<p>In case of any questions, please use our identification number ${failure.number}</p>` : ''}
<p>Our contacts:</p>
<p>servis@electric-components.cz</p>
${user.email ? `<p>${user.email}</p>` : ''}
<br/>
<p>Best regards</p><br/>

<p>${user.name ? `${user.name}` : ''}<br/>
Electric Components a.s.<br/>
<img src="https://firebasestorage.googleapis.com/v0/b/rams-e6d4f.appspot.com/o/logoLarge.png?alt=media&token=1591741a-7f97-48fe-a0d2-aefd57a11f46"/><p/>`
}

