const { today, nextWeek } = require('./dates')

module.exports = {
    id: 'en',
    label: 'English',
    filename: 'Order form.pdf',
    subject: (failure, user) => `Completion of order ${failure.number ? `No. ${failure.number}` : ''}`,
    html: (failure, user) => `<p>Dear Customer, on ${today('en')} we finished your requirement for the repair no. ${failure.number} ${failure.order_number ? ` on the basis of your order no. ${failure.order_number}` : ''}</p><br/>
    <p>A summary of your order can be found in the attachment to this message.</p><br/>
    <p>The expected date of the shipment is ${today('en')}</p><br/>
${failure.number ? `<p>In case of any questions, please use our identification number ${failure.number}</p>` : ''}
<p>Our contacts:</p>
<p>servis@electric-components.cz</p>
${user.email ? `<p>${user.email}</p>` : ''}
<br/>
<p>Thank you and we look forward to further cooperation.</p><br/>

<p>${user.name ? `${user.name}` : ''}<br/>
Electric Components a.s.<br/>
<img src="https://firebasestorage.googleapis.com/v0/b/rams-e6d4f.appspot.com/o/logoLarge.png?alt=media&token=1591741a-7f97-48fe-a0d2-aefd57a11f46"/><p/>`
}

