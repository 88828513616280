const moment = require('moment')

module.exports = {
    id: 'de',
    label: 'Deutsch',
    filename: 'Bestellformular.pdf',
    subject: (failure, user) => `Abnahme von Reparaturmaterial  ${failure.number ? `Nr. ${failure.number}` : ''}`,
    html: (failure, user) => `<p>Sehr geehrter Kunde, ${failure.progress.reported.at ? `am ${moment(failure.progress.reported.at).locale('de').format('LL')}` : ''} haben wir das Material zur Reparatur gemäß der beigefügten Datei erhalten.</p><br/>
${failure.order_number ? `<p/>Ihre Bestellung: ${failure.order_number}</p><br/>` : ''}
${failure.progress.estimated.at ? `<p>Geschätzter Liefertermin ist ${moment(failure.progress.estimated.at).locale('de').format('LL')}</p><br/>` : ''}
${failure.number ? `<p>Bei Fragen verwenden Sie bitte immer unsere Identifikationsnummer ${failure.number} und ` : ''} kontaktieren Sie uns unter E-Mail servis@electric-components.cz ${user.email ? `oder ${user.email}</p><br/>` : ''}

<p>Wir bedanken Ihnen</p><br/>

<p>${user.name ? `${user.name}` : ''}<br/>
Electric Components a.s.<br/>
<img src="https://firebasestorage.googleapis.com/v0/b/rams-e6d4f.appspot.com/o/logoLarge.png?alt=media&token=1591741a-7f97-48fe-a0d2-aefd57a11f46"/><p/>`
}
