const { today, nextWeek } = require('./dates')

module.exports = {
    id: 'cs',
    label: 'Česky',
    filename: 'Zakázkový list.pdf',
    subject: (failure, user) => `Ukončení zakázky ${failure.number ? `č. ${failure.number}` : ''}`,
    html: (failure, user) => `<p>Vážený zákazníku, dne ${today('cs')} jsme dokončili váš požadavek na opravu č. ${failure.number} ${failure.order_number ? ` na základě Vaší objednávky č. ${failure.order_number}` : ''}</p><br/>
    <p>Souhrn vaší zakázky je v přiloženém souboru.</p><br/>
    <p>Předpokládané datum expedice je ${today('cs')}</p><br/>
${failure.number ? `<p>V případě dotazů vždy používejte naše identifikační číslo ${failure.number} a ` : ''} kontaktujte nás na e-mailech servis@electric-components.cz ${user.email ? `nebo ${user.email}</p><br/>` : ''}

<p>Děkujeme a těšíme se na další spolupráci.</p><br/>

<p>${user.name ? `${user.name}` : ''}<br/>
Electric Components a.s.<br/>
<img src="https://firebasestorage.googleapis.com/v0/b/rams-e6d4f.appspot.com/o/logoLarge.png?alt=media&token=1591741a-7f97-48fe-a0d2-aefd57a11f46"/><p/>
`
}
