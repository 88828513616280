const moment = require('moment')

module.exports = {
    id: 'cs',
    label: 'Česky',
    filename: 'Přijetí materiálu na opravu.pdf',
    subject: (failure, user) => `Přijetí materiálu na opravu ${failure.number ? `č. ${failure.number}` : ''}`,
    html: (failure, user) => `<p>Vážený zákazníku, ${failure.progress.reported.at ? `dne ${moment(failure.progress.reported.at).locale('cs').format('LL')}` : ''} jsme do opravy přijali materiál dle přiloženého souboru.</p><br/>
${failure.order_number ? `<p/>Vaše objednávka: č. ${failure.order_number}</p><br/>` : ''}
${failure.progress.estimated.at ? `<p>Předpokládané datum plnění je ${moment(failure.progress.estimated.at).locale('cs').format('LL')}</p><br/>` : ''}
${failure.number ? `<p>V případě dotazů vždy používejte naše identifikační číslo ${failure.number} a ` : ''} kontaktujte nás na e-mailech servis@electric-components.cz ${user.email ? `nebo ${user.email}</p><br/>` : ''}

<p>Děkujeme</p><br/>

<p>${user.name ? `${user.name}` : ''}<br/>
Electric Components a.s.<br/>
<img src="https://firebasestorage.googleapis.com/v0/b/rams-e6d4f.appspot.com/o/logoLarge.png?alt=media&token=1591741a-7f97-48fe-a0d2-aefd57a11f46"/><p/>
`
}
